<template>
  <div>
      <!-- 导航 -->
    <a-breadcrumb separator=">" class="breadcrumb">
      <a-breadcrumb-item>
        <a @click.prevent="$router.push('/index')">首页</a>
      </a-breadcrumb-item>
      <a-breadcrumb-item>
        <a @click.prevent="$router.push('/notice')">公告栏</a>
      </a-breadcrumb-item>
      <a-breadcrumb-item>
        公告详情
      </a-breadcrumb-item>
    </a-breadcrumb>

    <div class="container">
      <router-view />
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="less" scoped>
.container {
  max-width: 1440px;
  min-width: 990px;
  margin: 0 auto;
  padding:  42px;
  background-color: #fff;
  border-width: 1px;
  border-style: solid;
  border-color: rgba(228, 228, 228, 1);
  margin-bottom: 62px;
}
</style>